<template>
    <ImportTokenBtn :text="text" @click="RequestAddTokenToWallet"/>
</template>


<script>

import {Ethers, MSG} from '../../scripts/ethers.js';
import ImportTokenBtn from "./importTokenButton.vue";
export default {

    props:["text", "address", "symbol", "icon"],
    components:{ImportTokenBtn},   
    methods:{
        async RequestAddTokenToWallet(){
            let x = await Ethers.RequestAddTokenToWallet(this.address, this.symbol, this.icon, 18)           
            console.log(x==true);
        }
        
    }
}
</script>