<template>
    <ImportTokenBtn :text="text ?? `import_token`" :address="Address" :symbol="Symbol" :icon="Icon" />
</template>

<script>
// @ is an alias to /src
import ImportTokenBtn from './importErc20Button.vue'
import {CONTRACT_HEX} from '../../abi';
export default {
    props:["text"],
    components:{ImportTokenBtn},

    computed:{
        Address(){return CONTRACT_HEX.address},        
        Symbol() {return process.env.VUE_APP_HEX_SYMBOL},      
        Icon()   {return process.env.VUE_APP_HEX_ICON}
    }

}
</script>