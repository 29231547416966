<template>
<nav class="navbar navbar-expand-md sticky-top bg-dark navbar-dark">
    <div class="container-fluid">
        <router-link class="navbar-brand d-md-none" to="/">
            <img src="/assets/img/ww_icon.png" width="100" height="100" alt="Logo" style="width: 45px; height: auto;" />
        </router-link>
        <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navcol-1">
            <span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span>
        </button>

        <div id="navcol-1" class="collapse navbar-collapse d-md-flex">
            <ul class="navbar-nav d-flex justify-content-evenly mx-auto w-100">
                
                <li class="nav-item">
                    <LocalMenu />
                </li>
                
                <!--li class="nav-item">
                    <router-link class="nav-link active text-center" to="/news"><span style="color: rgba(255, 255, 255, 0.75);">{{ $t('nav_news') }}</span></router-link>
                </li-->
                <li class="nav-item">
                    <router-link class="nav-link active text-center" to="/tokens"><span style="color: var(--bs-nav-link-hover-color);">{{$t('nav_tokens')}}</span></router-link>
                </li>

            </ul>
            
            <router-link class="navbar-brand d-none d-md-block me-0" to="/">
                <img src="/assets/img/ww_icon.png" width="100" height="100" alt="Logo" style="width: 45px;height: auto;" />
            </router-link>

            <ul class="navbar-nav d-flex justify-content-evenly mx-auto w-100">
                <li class="nav-item">
                    <router-link class="nav-link active text-center" to="/market"><span style="color: var(--bs-nav-link-hover-color);">{{$t('nav_market')}}</span></router-link>
                </li>
                <li class="nav-item pb-2 pb-md-0">
                    <a class="nav-link active text-center" :href="wp_url"  target="_blank"><span style="color: var(--bs-nav-link-hover-color);">{{ $t('nav_wp') }}</span></a>
                </li>
            </ul>
            <connectButton/>
            <!--a id="connect_wallet_btn" class="btn btn-primary btn_1_outline" role="button" href="#" style="min-width: 150px;white-space:nowrap; overflow: hidden; text-overflow: ellipsis;" ><div class="dot-flashing"></div></a-->
        </div>
    </div>
</nav>
</template>

<script>

import connectButton from "./connectButton.vue";
import LocalMenu from "./localeMenu.vue";
export default{

    components:{
        connectButton, LocalMenu
    },
    data(){
        return {
            wp_url:process.env.VUE_APP_WP_URL 
        }
    }
}
</script>