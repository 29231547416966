<template>
<div>
    <header class="page-header">
        <div class="row mb-2">
            <div class="col-md-8 col-xl-6 text-center mx-auto">
                <h1>{{ $t('market') }}</h1>
                <hr class="hr-purple-center">
            </div>
        </div>
    </header>

    <section class="market_place" style="padding: 60px 0;">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-xl-6 text-center mx-auto">
                    <h2 class="display-6 page_header" style="color: #fff;">{{ $t('lootbox') }}</h2>
                </div>
            </div>
        </div>
        
        <!--LootBoxHeroContainer Title="Core Set Loot Box" Icon="/assets/img/LootBox-Core.png" :Items="coreset" Supply="88" Price="119" />   

        <div class="container" style="padding: 20px 0 80px 0;">

            <div class="row gy-4 row-cols-1 row-cols-sm-1 row-cols-md-2 mb-3">   
                <div class="col">
                    <LootBoxContainer Title="Comander Loot Box" Icon="/assets/img/LootBox-Comander.png" :Items="commander" Supply="10000" Price="119" />
                </div>
                <div class="col">
                    <LootBoxContainer Title="Wonder Loot Box" Icon="/assets/img/LootBox-Wonder.png" :Items="wonder" Supply="20000" Price="119" />
                </div>
            </div>

            <div class="row gy-4 row-cols-1 row-cols-sm-1 row-cols-md-2">
                <div class="col">
                    <LootBoxContainer Title="Action Loot Box" Icon="/assets/img/LootBox-Action.png" :Items="action" Supply="20000" Price="119" />
                </div>
                <div class="col">
                    <LootBoxContainer Title="Unit Loot Box" Icon="/assets/img/LootBox-Army.png" :Items="unit" Supply="40000" Price="119" />
                </div>
            </div>
            
        </div-->

        <!--div style="text-align: center;">-----------------------------------------------------------</div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-xl-6 text-center mx-auto">
                    <h2 class="display-6 page_header" style="color: #fff;">{{ $t('lootbox') }}</h2>
                </div>
            </div>
        </div-->

        <div class="container" style="padding: 20px 0 80px 0;">

            <div class="row gy-4 row-cols-1 row-cols-sm-1 row-cols-md-2 mb-3">
                
                <div class="col">
                    <LootBoxContainer Title="Core Set Loot Box" Icon="/assets/img/LootBox-Core.png" :Items="coreset" :max="maxPurchase"  />
                </div>   
                <div class="col">
                    <LootBoxContainer Title="Comander Loot Box" Icon="/assets/img/LootBox-Comander.png" :Items="commander" :max="maxPurchase"/>
                </div>
            </div>

            <div class="row gy-4 row-cols-1 row-cols-sm-1 row-cols-md-3">
                <div class="col">
                    <LootBoxContainer Title="Wonder Loot Box" Icon="/assets/img/LootBox-Wonder.png" :Items="wonder" :max="maxPurchase" />
                </div>
                <div class="col">
                    <LootBoxContainer Title="Action Loot Box" Icon="/assets/img/LootBox-Action.png" :Items="action" :max="maxPurchase"/>
                </div>
                <div class="col">
                    <LootBoxContainer Title="Unit Loot Box" Icon="/assets/img/LootBox-Army.png" :Items="unit" :max="maxPurchase" />
                </div>
            </div>

        </div>

    </section>
</div>
</template>

<style lang="scss">

$loot_hue:hue-rotate(263deg);
$loot_shadow:drop-shadow(2px 4px 6px black);
.blind_box_container, .blind_box_top{
    .card-img-top, .box_image{
        max-width: 225px;
        filter: $loot_shadow !important;
    }
    
    &.action-card{
        .card-img-top{
            filter: $loot_hue $loot_shadow !important;
        }
        .quantity_box img{
            filter: $loot_hue !important;
        }   
    }
}
</style>

<script>
// @ is an alias to /src
//import LootBoxHeroContainer from '../components/lootbox/lootBoxHeroContainer.vue'
import LootBoxContainer from '../components/lootbox/lootBoxContainer.vue'
import { reactive } from 'vue'
import {CONTRACT_LOOTBOX, CONTRACT_USDT} from '../abi';

export default {
    components:{LootBoxContainer},
    
    data(){
        return{
            totalSold:0,
            currentAvailable:0,
            maxPurchase:5,
            // lootbox data info
            coreset:{},
            commander:{},
            wonder:{},
            action:{},
            unit:{}
        }   
    },
    created(){
        this.coreset=reactive({
                address:CONTRACT_LOOTBOX.address_CoreSet,
                abi:CONTRACT_LOOTBOX.abi,
                guarantee:[
                        {qty:1,item:"core set", desc:"An original hand crafted Card"},
                ],
                contents:this.translatedCoreset
            });
            this.commander=reactive({
                address:CONTRACT_LOOTBOX.address_Commander,
                abi:CONTRACT_LOOTBOX.abi,
                guarantee:[
                    {qty:1,item:"commander"}
                ],
                contents:this.translatedCommander
            });
            this.wonder=reactive({                
                address:CONTRACT_LOOTBOX.address_Wonder,
                abi:CONTRACT_LOOTBOX.abi,
                guarantee:[
                        {qty:1,item:"wonder"}
                ],
                contents:this.translatedWonder                
            });
            this.action=reactive({
                address:CONTRACT_LOOTBOX.address_ActionCard,
                abi:CONTRACT_LOOTBOX.abi,
                guarantee:[
                        {qty:1,item:"action card"}
                ],
                contents:this.translatedAction                
            });
            this.unit=reactive({
                address:CONTRACT_LOOTBOX.address_Unit,
                abi:CONTRACT_LOOTBOX.abi,
                guarantee:[
                    {qty:1,item:"unit"}
                ],
                contents:this.translatedUnit                
            });
    },
    computed: {
        translatedCoreset() {
            return [
                this.$t('lootbox_core_contents'),
                `$(${process.env.VUE_APP_HEX_SYMBOL}) ${this.$t('lootbox_lottery')}`
            ];
        },
        translatedCommander(){
            return [
                    this.$t('lootbox_rarity_chance'),
                    `$(${process.env.VUE_APP_HEX_SYMBOL}) ${this.$t('lootbox_lottery')}`,
                    this.$t('lootbox_bonus_card_chance')
                ]
        },
        translatedWonder(){
            return [
                    this.$t('lootbox_rarity_chance'),
                    `$(${process.env.VUE_APP_HEX_SYMBOL}) ${this.$t('lootbox_lottery')}`,
                    this.$t('lootbox_bonus_card_chance')
                ]
        },
        translatedAction(){
            return [            
                    this.$t('lootbox_rarity_chance'),
                    `$(${process.env.VUE_APP_HEX_SYMBOL}) ${this.$t('lootbox_lottery')}`
                ]
        },
        translatedUnit(){
            return [          
                    this.$t('lootbox_rarity_chance'),
                    `$(${process.env.VUE_APP_HEX_SYMBOL}) ${this.$t('lootbox_lottery')}`,
                ]
        }
    },
    watch: {
    // Watch for changes to the locale to update `coreset.contents`
    '$i18n.locale': {
      handler() {
        // Update contents dynamically whenever the locale changes
        this.coreset.contents   = this.translatedCoreset;
        this.commander.contents = this.translatedCommander;
        this.wonder.contents    = this.translatedWonder;
        this.action.contents    = this.translatedAction;
        this.unit.contents      = this.translatedUnit;
      },
      immediate: true  // Run the handler immediately on component mount
    }
  }
}
</script>
