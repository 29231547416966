<template>
    <a class="btn btn-primary active btn_arrows" role="button">
        <img src="/assets/img/arrow_left.png">
        {{ $t(text) }}
        <img src="/assets/img/arrow_right.png">
    </a>
</template>


<script>

export default {
    props:["text"]
}
</script>