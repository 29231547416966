<template>
    <span @click="CopyToClipBoard($event, address)"  data-bs-toggle="tooltip" :title="`${$t('wallet_address_copy')}\n${address}`" class="address">
        <span :class="[ (noBorder ? '' : 'under-border'), 'invert d-inline-block text-truncate']" :style="style">{{address}}</span>
        <!-- i class="fas fa-clone"></i -->
        <input readonly style="position:absolute; left:-9999999px; filter:opacity(0);" ref="fallback" @focus="$event.target.select()" type="text"  :value="address" />
    </span>
</template>

<style lang="scss" scoped>
    .address{
        cursor: pointer;
    }
</style>

<script>
import Tooltip from '../../node_modules/bootstrap/js/src/tooltip';

export default {
    props:['address', 'width', "noBorder"],

    mounted(){
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(i => new Tooltip(i));
    },

    computed: {
        style(){ return `max-width:${this.width}px; vertical-align: bottom;`;}
    },

    methods:{
        CopyToClipBoard(e, text){            
            if(this.addressCopied){return;}
            let clipboardData =
            e.clipboardData ||
            window.clipboardData ||
            e.originalEvent?.clipboardData ||
            navigator.clipboard;

            if(clipboardData){
                clipboardData.writeText(text);
            }else{
                this.$refs.fallback.focus();
                document.execCommand('copy');
            }
            this.addressCopied=true;
            setTimeout(()=>{this.addressCopied=false;},1500)
        }
    }
}
</script>
