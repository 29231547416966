export default {
    nftBaseURI      :(state) => state.nftBaseURI,
    gloBalance      :(state) => state.gloBalance,
    hexBalance      :(state) => state.hexBalance,
    abeyBalance     :(state) => state.abeyBalance,
    usdtBalance     :(state) => state.usdtBalance,
    wtcBalance      :(state) => state.wtcBalance,
    gloFetching     :(state) => state.gloFetching,
    hexFetching     :(state) => state.hexFetching,
    abeyFetching    :(state) => state.abeyFetching,
    usdtFetching    :(state) => state.usdtFetching,
    wtcFetching     :(state) => state.wtcFetching,
    ownedNFTs       :(state) => state.ownedNFTs,
    gloContract     :(state) => state.gloContract,
    hexContract     :(state) => state.hexContract, 
    wtcContract     :(state) => state.wtcContract, 
    usdtContract    :(state) => state.usdtContract,
}