<template>
    <div class="btn-container">
        
<!-- No Support Button/Modal -->           
        <bs-modal v-if="!supported" styles="wallet" contentStyles="wallet-content">
            <template #trigger="{onClick}" class="text-dingy-stock trigger">
                <a class="btn btn-primary" @click="onClick" role="button" style="min-width: 150px;white-space:nowrap;" >{{ $t('wallet_connect') }}</a>   
            </template>

            <div class="text-grummpy-dragon trigger" slot="trigger"> {{ $t('wallet_no_support') }} <i class="far fa-question-circle"></i></div>
            
            <template #header> 
                <div class="modal-header d-flex justify-content-center flex-column pb-0">
                    <h2 class="modal-title">{{ $t('wallet_no_support') }}</h2>
                </div>              
            </template>

            <template #body>
                <div  class="modal-body text-center">
                    <p>{{ $t('wallet_no_support_found') }}</p>
                    <p>{{ $t('wallet_no_support_suggest') }}</p>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer  align-item-center justify-content-around">
                    <button type="button" class="btn btn-success col-4" data-bs-dismiss="modal">{{ $t('button_close') }}</button>
                    <a class="btn btn-primary text-center border-white  col-4"  :href="findWalletUrl" target="_blank">{{ $t('wallet_find') }}</a>
                </div>
            </template>

        </bs-modal>
       
   
<!-- Looking for account already authed -->
        <a v-else-if="!initialized" class="btn btn-primary" role="button" href="#" style="min-width: 150px;white-space:nowrap; overflow: hidden; text-overflow: ellipsis;" ><div class="dot-flashing"></div></a>   

<!-- Wallet Button/Modal -->
        <bs-modal v-else-if="connected" :onOpen="PullBalances" styles="wallet" contentStyles="wallet-content">
            <template #trigger="{onClick}" class="text-dingy-stock trigger">
                <a class="btn btn-primary" @click="onClick" role="button" style="min-width: 150px;white-space:nowrap;"  >{{ $t('wallet') }}</a>   
            </template>
                       
            <template #header> 
                <div class="modal-header d-flex justify-content-center flex-column pb-0">
                    <h2 class="modal-title">{{ $t('wallet') }}</h2>
                    <span>{{ $t('wallet_address') }}:<contract-address :address="address" :width="100" noBorder="true" label="Address:" /></span>
                </div>              
            </template>

            <template #body>
                <div  class="modal-body">
                    <ul class="list-group list-group-horizontal balance-table">
                    <!-- GLO-->
                        <li class="list-group-item">
                            <img :src="GloIcon" />
                            <span>$({{GloSymbol}})</span> 
                            <div v-if="gloFetching" class="dot-flashing"></div>
                            <span v-else class="bal">{{gloBalance}}</span>                            
                        </li>
                    <!-- WTC-->
                        <li class="list-group-item">
                            <img :src="WtcIcon" data-bs-dismiss="modal" @click='$router.push({name:"card_view"})' style="cursor: pointer;" />
                            <span>$({{WtcSymbol}})</span> 
                            <div v-if="wtcFetching" class="dot-flashing"></div>
                            <span v-else class="bal">{{wtcBalance}}</span>
                        </li>
                    <!-- HEX -->
                        <li class="list-group-item">
                            <img :src="HexIcon" />
                            <span>$({{HexSymbol}})</span> 
                            <div v-if="hexFetching" class="dot-flashing"></div>
                            <span v-else class="bal">{{hexBalance}}</span>
                        </li>
                    </ul>
                    
                    <div class="d-flex row other-currency">   
                        <div class="col-6">
                        <!-- ABEY -->
                            <div class="d-flex">  
                                <img class="token-icon" :src="AbeyIcon" />
                                <div v-if="abeyFetching">
                                    <div class="dot-flashing"  style="right:-22px;"></div>
                                </div>
                                <span v-else class="bal">{{abeyBalance}}</span>
                            </div>  
                        <!-- USDT -->
                            <div class="d-flex">                            
                                <img class="token-icon" :src="USDTIcon" />
                                <div v-if="usdtFetching">
                                    <div class="dot-flashing" style="right:-22px;"></div>
                                </div>
                                <span v-else class="bal">{{usdtBalance}}</span>
                            </div>
                        </div>
                        <div class="col-6 align-self-center">
                            {{ $t('wallet_network') }}:  {{networkString}}
                        </div>                 
                        
                            
                    </div>

                
                </div>
            </template>
            <template #footer>
            <div class="modal-footer align-item-center justify-content-center">
                <button type="button" class="btn btn-primary text-center border-white col-6" data-bs-dismiss="modal">{{ $t('button_close') }}</button>
            </div>
            </template>
                
           
        </bs-modal>





<!-- Connect Button -->
        <a v-else class="btn btn-primary" role="button" @click="ConnectAccount" style="min-width: 150px;white-space:nowrap; overflow: hidden; text-overflow: ellipsis;" >{{ $t('wallet_connect') }}</a>
        

       
<!-- Error Modal -->
        <bs-modal v-on:mounted="setErrorTrigger">

            <template #header>
                <div class="modal-header justify-content-center">
                    <h2 class="modal-title">{{ $t('wallet_locked') }}</h2>
                    </div>
            </template>
            <template #body>
                <div class="modal-body">
                    <p>{{ $t('wallet_pending_request') }}</p>
                    <p>{{ $t('wallet_resovle_pending') }}</p>
                </div>
            </template>
            <template #footer>
                <div class="modal-footer align-item-center">
                    <button type="button" class="btn btn-lg btn-primary" data-bs-dismiss="modal">{{ $t('button_ok') }}</button>
                </div>
            </template>

        </bs-modal>

    </div>
            


 
</template>

<script>
import bsModal from './bootstrap/modal.vue';
import { mapGetters } from 'vuex';
import {Ethers, MSG} from '../scripts/ethers.js';
//import {CONTRACT_GLO, CONTRACT_HEX, CONTRACT_WTC, CONTRACT_USDT} from '../abi';
import contractAddress from './contractAddress.vue';
import ImportGloBtn from './tokenImport/gloImportButton.vue';
import ImportHexBtn from './tokenImport/hexImportButton.vue';
import EventBus from '../EventBus.js';
const storage = window.localStorage;
export default {
    
    components:{bsModal, contractAddress, ImportGloBtn, ImportHexBtn},    

    computed:{
        ...mapGetters('ethers',[
            'supported',
            'initialized',
            'connected',  
            'error',      
            'address',    
            'ens',        
            'network']),
        ...mapGetters(
            'wallet',[
            'gloBalance',    
            'hexBalance',    
            'abeyBalance',    
            'usdtBalance',   
            'wtcBalance',   
            'gloFetching',    
            'hexFetching',   
            'abeyFetching',   
            'usdtFetching',  
            'wtcFetching',  
            'ownedNFTs',
            'wtcContract'     
        ]),
        networkString() {return this.network ? (`${(this.network).chainHex} ${(this.network).name}`) : "--";},
        findWalletUrl() {return process.env.VUE_APP_FIND_WALLET_URL;},
        GloSymbol()     {return process.env.VUE_APP_GLO_SYMBOL},    
        HexSymbol()     {return process.env.VUE_APP_HEX_SYMBOL},   
        WtcSymbol()     {return process.env.VUE_APP_WTC_SYMBOL},        
        AbeySymbol()    {return process.env.VUE_APP_ABEY_SYMBOL},       
        USDTSymbol()    {return process.env.VUE_APP_USDT_SYMBOL},
        GloIcon()       {return process.env.VUE_APP_GLO_ICON},
        HexIcon()       {return process.env.VUE_APP_HEX_ICON},
        WtcIcon()       {return process.env.VUE_APP_WTC_ICON},
        AbeyIcon()      {return process.env.VUE_APP_ABEY_ICON},
        USDTIcon()      {return process.env.VUE_APP_USDT_ICON},   
        
    },
    
    created(){
        EventBus.on(process.env.VUE_APP_WALLET_CONNECT_EVENT, (event) => { this.ConnectAccount(); })
    },
    
    methods:{
        ConnectAccount(){
            this.$store.dispatch('ethers/connect')
        },
        setErrorTrigger(modal){
            Ethers.On(MSG.WALLET_LOCKED, () => {
                console.log("setErrorTrigger", "WALLET_LOCKED");
                modal.show();
            });
        },
        ErrorModalClosed(){
            console.log("ErrorModalClosed", "ErrorModalClosed");
            this.errorOpen=false;
        },
        async PullBalances(){
            this.$store.dispatch('wallet/SyncAll')
        }       
    }
}
</script>

<style lang="scss">
   // @import '../../scss/colors.scss';

    .wallet{
        color: #fff;
        .wallet-content{
            background: rgba(46,0,66,.7);
        }
        .modal-title, .modal-title{
            color: #fff;
            padding: 0;
        }
        .modal-body{
            .other-currency{
                font-size:10pt; 
                font-style:italic; 
                font-style: normal;
                margin:5px;
                justify-content: space-around; 
                background: rgba(46,0,66,0.35);
                border-radius: 10px;
                border: 1px solid #2e0042;
                padding: 12px 6px;
                .token-icon{align-self: center;max-width: 18px;max-height: 18px;}
                .bal{ align-self: center;margin-left: 4px; font-size:14pt;}
            }
        }
        .modal-header,.modal-footer{border:none;}
    }
    .modal-backdrop.show{
        background: url(/assets/img/blindbox_bg.jpg) top / cover;
        opacity: .95;
    }

    
    
    
    
    .input-group-text{
     //   background-color: $spooky-twilight;
    }
    .balance-table{
        display:flex;
        flex-direction:row;
        justify-content:center;
        background: rgba(46,0,66,0.35);
        border-radius: 10px;
        border: 1px solid #2e0042;
       // padding: 45px 25px;

        .list-group-item {
            display:flex;
            flex-direction:column;
            align-items:center;
            flex:1;
            background-color: transparent;
            border-color: #2e0042;;
            color: #fff;
            h5{
                color: #fff;
            }

            h5{
                font-family: ode;
            }
            img{
                max-width:72px;
            }
            .bal{
                font-weight: bold;
            }
        }
    }    

</style>
