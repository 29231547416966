<template>
    <section class="build" style="background: var(--bs-info);">
        <div class="container py-4 py-xl-5">
            <div class="row mb-5">
                <div class="col-md-8 col-xl-6 text-center mx-auto">
                    <h2 class="display-5 fw-bold">{{ $t('homeview_build') }}</h2>
                    <hr class="hr-purple-center">
                    <h4 v-html="$t('homeview_build_text')"></h4>    
                </div>
            </div>
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/market_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('market')}}</h3>
                        <router-link class="btn btn-primary active btn_1" role="button" to="/market">{{$t('market_place')}}</router-link>
                        <!--a class="btn btn-primary active btn_1" role="button" href="/glöstöne.html">Market Place</a-->
                    </div>
                </div>
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/dev_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('dev')}}</h3><a class="btn btn-primary btn_1" role="button" href="#">{{$t('coming_soon')}}</a>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/doc_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{ $t('docs') }}</h3><a class="btn btn-primary btn_1" role="button" href="#">{{$t('coming_soon')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>