<template>
<a :class="['ply-now-btn', {'fetching': isDisabled}]" role="button">
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53.74 27.95" style="enable-background:new 0 0 53.74 27.95;" xml:space="preserve">
		<g>
        <path fill="currentcolor" d="M15.28,12.69l-3.37,5.66l-3.56-4.7l6.95-5.67l11.53,10.8l0.86,0.8l1.08-0.05l24.46-1.14l-0.05-4.71l-23.35,0.25c-3.16-2.96-13.9-13.01-14.28-13.37c-2.79,2.28-12.13,9.9-14.91,12.17c2.3,3.04,8.73,11.53,11.02,14.56l2.61-2.16l7.89-6.52L15.28,12.69z"/>
        <path fill="currentColor" d="M32.25,23.57c-2.66-0.05-9.62-0.2-12.38-0.25l-0.09,2.18c2.74,0.17,9.8,0.61,12.45,0.77c2.88,0.13,17.57-0.13,20.76-0.11c0,0-0.1-3.49-0.1-3.49C49.81,22.76,35.04,23.58,32.25,23.57z"/>
		</g>
	</svg>
    <span v-if="isDisabled" class="dots"></span>
    <span :class="{'download_text_fetching':isDisabled}">{{$t('button_download_now')}}</span>
	
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 53.74 27.95" style="enable-background:new 0 0 53.74 27.95;" xml:space="preserve">
        <g>
            <path fill="currentcolor" d="M38.59,12.69l3.37,5.66l3.56-4.7l-6.95-5.67l-11.53,10.8l-0.86,0.8l-1.08-0.05L0.64,18.38l0.05-4.71l23.35,0.25c3.16-2.96,13.9-13.01,14.28-13.37c2.79,2.28,12.13,9.9,14.91,12.17c-2.3,3.04-8.73,11.53-11.02,14.56l-2.61-2.16L31.7,18.6L38.59,12.69z"/>
            <path fill="currentcolor" d="M21.62,23.57c2.66-0.05,9.62-0.2,12.38-0.25l0.09,2.18c-2.74,0.17-9.8,0.61-12.45,0.77c-2.88,0.13-17.57-0.13-20.76-0.11c0,0,0.1-3.49,0.1-3.49C4.06,22.76,18.83,23.58,21.62,23.57z"/>
        </g>
    </svg>
</a>
</template>

<style lang="scss">

.ply-now-btn{	
	--cp:12px; /* Corner cut size*/
	--cpb:1px; /* outline/border thinkness */

	display: inline-flex;
	align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
	/* space-evenly */
	justify-content: space-evenly;
	/* -- or hard coded --    
	justify-content: center;
	padding: 5px 6px;
	*/
    z-index:10;
    //width: 300px;
    min-height: 50px;
	margin: 15px 0;
    letter-spacing: 1px;    
    font-size: 24px;
	color:white;
	position: relative;
    text-decoration: none;
    font-weight: bold;
    transition: all .15s ease-in-out;	
    
    &:before{	
        content: "";
        position: absolute;
        inset: 0;
        z-index:-1;
        background-color: #fff;
        clip-path: polygon(0 var(--cp),var(--cp) 0,calc(100% - var(--cp)) 0,100% var(--cp),100% calc(100% - var(--cp)),calc(100% - var(--cp)) 100%,var(--cp) 100%,0 calc(100% - var(--cp)),0 var(--cp),var(--cpb)  calc(var(--cp) + 0.41px),var(--cpb) calc(100% - var(--cp) - 0.41px),calc(var(--cp) + 0.41px) calc(100% - var(--cpb)),calc(100% - var(--cp) - 0.41px) calc(100% - var(--cpb)),calc(100% - var(--cpb)) calc(100% - var(--cp) - 0.41px),calc(100% - var(--cpb)) calc(var(--cp) + 0.41px),calc(100% - var(--cp) - 0.41px) var(--cpb),calc(var(--cp) + 0.41px) var(--cpb),var(--cpb) calc(var(--cp) + 0.41px));
        transition: all .15s ease-in-out;
    }
    &:hover {
	    color:purple;
        &:before{
	        --cpb:50px; /* fill in background with outline/border  !this might need to use the height in a var to be responsive*/
        }
    }
    svg {
        width: 40px;
        height: auto;
        margin: 0 18px;
        /* Needed with the "hard coded" option in .ply-now-btn
        padding: 0 5px 4px 5px;
        */
    }
    .download_text_fetching{visibility: hidden;}
    /* Button styling */
    &.fetching, &.fetching:hover {
        color:#afafaf;
        cursor: not-allowed; /* To show it's in a loading state */
        &:before{
            --cpb:50px;
            background-color:#777777 !important;
        }

        /* Dots after the 'Loading' text */
        .dots{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            height: 100%;

            &:after {
                content: "";
                
                position: absolute;
                display: flex;
                flex-direction: row;
                font-size: 46pt;        
                transform: translateY(-15%);
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;   
                /* Animation to add dots */
                animation: dots 1s steps(5, end) infinite;
            }
        }

        /* Keyframe animation for the dots */
        @keyframes dots {
            0%, 20% {
                content: ''; /* No dots */
            }
            40% {
                content: '.'; /* One dot */
            }
            60% {
                content: '..'; /* Two dots */
            }
            80%, 100% {
                content: '...'; /* Three dots */
            }
        }
    }
}
</style>

<script>
export default{
    
    props:['isDisabled'],
}
</script>