<template>

    <div class="card blind_box_container">
        <img class="card-img-top w-100 d-block box_image" :src="Icon">
        <h3 class="text-center" style="color: var(--bs-light);padding-top: 15px;">{{ Title }}</h3>

        <p class="text-center" style="padding: 20px 0;">
            {{$t('supply')}} : 
            <span v-if="ready">{{ Number(supply).toLocaleString()  }}</span> 
            <div v-else class="dot-flashing" style="right:-22px;"></div>
        </p>        
        
        <div class="blind_box_container">
            <p class="fs-6" style="border-bottom: 1px solid rgba(225,225,225,0.35);text-align: center;">{{ $t('item_contains') }}:</p>
            <div class="row">

                <div class="col" v-for="(g,i) in guarantee" :key="i">
                    <div class="d-flex coreset_des-1">
                        <img class="img-fluid question_card" src="/assets/img/unknown_card.png">
                        <div class="d-flex align-items-center align-items-center">
                            <div class="d-flex mx-auto align-items-center" style="line-height: 2.5em;">
                                <p class="fw-bold d-xxl-flex align-items-xxl-center number-x" style="padding-right: 18px;letter-spacing: 4px;">X{{ g.qty }}</p>
                                <p class="fs-5 text-danger d-xxl-flex align-items-xxl-center item_title" style="--bs-body-color: var(--bs-primary);">{{ g.item.toUpperCase() }}<br>{{$t('guarantee')}}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
 
            <ul v-if="contents.length">
                <li v-for="(t,i) in contents" :key="i">{{t}}</li>
            </ul>
          
            
        </div>

        <div class="blind_box_container d-flex flex-column justify-content-center" style="margin-top: 15px; position: relative;">
            <span class="text-white text-center" style="position:absolute; top:12px;left:0; width:100%;">SUPPLY: {{ supply }}</span>
            <LootPurchaseBox :Title="Title" :Icon="Icon" :price="price" :supply="supply" :ready="ready" :isApprove="isApprove" @approve="(qty)=>this.Approve(qty)" @purchase="(qty) => this.BuyLoot(qty)" />
        </div>
</div>


</template>

<script>
import {Ethers, MSG} from '../../scripts/ethers.js';
import LootPurchaseBox from './lootPurchaseBox.vue'
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { mapGetters } from 'vuex';
import {CONTRACT_USDT} from '../../abi';

export default{
    props:["Title", "Icon", "Items"],
    components:{LootPurchaseBox},

    created(){
        this.lootContract = Ethers.GetContract(this.Items.address, this.Items.abi)
        this.usdtContract = Ethers.GetContract(CONTRACT_USDT.address, CONTRACT_USDT.abi)
    },
    mounted(){
        // this should run always, you dont need a wallet connected to see whats for sale
        this.PullData();
    },
    watch: {
        address () {
            this.PullData();
        }
    },

    methods:{

        async PullData(){
            try {
                console.log("PULLDATA");
                this.fetchIsApprove()
                let res = await this.lootContract.bootBoxInfo();
                this.sold   = BigNumber(res[0]).toNumber();
                this.supply = BigNumber(res[1]).toNumber();
                this.price  = Ethers.FromWei(await this.lootContract.price());
                this.ready  = true;
                this.$emit("loaded", { sold: this.sold, supple: this.supply });
            } catch (e) {
                console.log("PullData ERROR", this.Title, e);
                this.ready = true;
            }

        },

        async fetchIsApprove () {
            if (!this.address) {
                console.log("fetchIsApprove: No Address");
                return;
            }
            const usdtBalance = await this.usdtContract.balanceOf(this.address)
            const allowance = await this.usdtContract.allowance(this.address, this.Items.address)
            this.usdtBalance = Ethers.FromWei(usdtBalance)
            this.isApprove = BigNumber(allowance.toString()).gt(0) && BigNumber(usdtBalance.toString()).lte(BigNumber(allowance.toString()))
        },
        // TODO: ~

        async getSignedContract(address, abi){ 
            const provider = new ethers.BrowserProvider(window.ethereum)
            const signer = await provider.getSigner()
            return new ethers.Contract(address, abi, signer)
        },

        async BuyLoot (qty) {
            const lootSignedContract = await this.getSignedContract(this.Items.address, this.Items.abi)

            if (BigNumber(this.usdtBalance).lt(this.price)) { 
                // Tip: Insufficient balance
                alert('Insufficient balance');
                return
            }

            try {
                const res = await lootSignedContract.buyLootBox(qty)
                console.log('Purchase results', res);
            } catch (error) {
                alert('Purchase failed, please open the console to view details');
                console.log('Purchase error', error);
            } finally { 
                this.PullData()
            }
        },
        async Approve () {
            const usdtSignedContract = await this.getSignedContract(CONTRACT_USDT.address, CONTRACT_USDT.abi)
            const max = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            try {
                const txResponse = await usdtSignedContract.approve(this.Items.address, max)

                const txReceipt = await txResponse.wait();
                console.log('Transaction Receipt:', txReceipt);

                if (txReceipt.status === 1) {
                    console.log('Approve Success');
                    await this.PullData();
                } else {
                    console.log('Approve Failed');
                }
            } catch (error) {
                alert('Approve Failed')
            }
        }
    },
    
    
    computed: {
        ...mapGetters('ethers',[
            'address',
        ]),
        guarantee(){ return this.Items.guarantee ?? []; },
        contents(){ return this.Items.contents ?? []; },               
    },
    data(){
        return {
            ready:false,
            supply:0,
            sold:0,
            price:0,
            lootContract: null,
            isApprove: false,
            usdtBalance: 0
        }
    }
    /*
    {
        guarantee:[
                {qty:1,item:"core set"}
        ],
        contents:[
            "Chance for one of the original hand crafted Commander, Wonder, Unit or Action Cards from the Origins Series",
            "$(HEX) Lottery",
            "1X Unit Card Chance of Boring, Fancy, or Super Fancy rarities"
        ]
    },
    {
        guarantee:[
                {qty:1,item:"commander"}
        ],
        contents:[
            "Chance of Boring, Fancy, or Super Fancy rarities",
            "$(HEX) Lottery",
            "1X Unit Card Chance of Boring, Fancy, or Super Fancy rarities"
        ]
    },
    {
        {
        guarantee:[
                {qty:1,item:"wonder"}
        ],
        contents:[
            "Chance of Boring, Fancy, or Super Fancy rarities",
            "$(HEX) Lottery",
            "1X Unit Card Chance of Boring, Fancy, or Super Fancy rarities"
        ]
        
    },
    {
        {
        guarantee:[
                {qty:1,item:"unit"}
        ],
        contents:[
            "Chance of Boring, Fancy, or Super Fancy rarities",
            "$(HEX) Lottery",
        ]
        
    }
    */

}

</script>