<template>
    <!-- clicking parent toggles drop down -->
    <div class="locale-select btn btn-dark"   @click="Open">

        <!-- drop down button showing the current locale selected -->
        <div class="nav-item selected" :class="{'select-arrow-active': isOpen}">
            <font-awesome-icon :icon="['fas', 'language']" />

            {{localDisplay}}
        </div>

        <!-- Selectable locales, current selected is hidden 
            only once the mouse moves over a selectable locale the selection box is hidden on mouse leave
        -->
        <transition  name="slide" >
        <ul class="select-items" v-if="isOpen"  >
            <li @click="switchLocale(lang.iso);"  
                v-for="(lang, i) in getLocales"
                :class="['locale-item', {'active':lang.iso==this.$i18n.locale}]"  
                :key="`Lang${i}`" 
                :value="lang.iso" 
                >
                {{ lang.translation }}
            </li>
        </ul>
    </transition>
    </div>
</template>
<style lang="scss">


$clipAmount:8px;

.locale-select {
    display: inline-flex;
    position: relative;
    overflow: visible;
    cursor: pointer;
    .selected {
        color:rgba(255, 255, 255, 0.75);
        background-color: transparent;
        &:after {
            position: absolute;
            content: "";
            bottom:6px;
            right: -6px;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-color: #6e49bc transparent transparent transparent;               
        }
        [class^="icon-"]{
            font-size:12pt;
        }
    }
    .selected.select-arrow-active::after{
        border-color: transparent transparent #6e49bc transparent;
        bottom: auto;
    }

    .select-items{        
        position: absolute;       
        top: 125%;
        height: 92px;        
        white-space: nowrap;        
        z-index: 99;
        font-size: 10pt;
        display: block;
        list-style: none;
        padding: 0;
        background-color:black;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - $clipAmount), calc(100% - $clipAmount) 100%, $clipAmount 100%, 0 calc(100% - $clipAmount));     
        transform-origin: top;
        &:before {
            --cpb:3px;
            content: '';
            position: absolute;
            top: -13px; /* Half the border width */
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff; /* Border color */
            background:linear-gradient(180deg, rgba(17,17,17,0) 7%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 100%);
            clip-path: polygon(0 0, 100% 0, 100% calc(100% - $clipAmount), calc(100% - $clipAmount) 100%, $clipAmount 100%, 0 calc(100% - $clipAmount));
            clip-path: polygon(
                0 $clipAmount,$clipAmount 0,
                calc(100% - $clipAmount) 0,
                100% $clipAmount,
                100% calc(100% - $clipAmount),calc(100% - $clipAmount) 100%,
                $clipAmount 100%,
                0 calc(100% - $clipAmount),
                0 $clipAmount,
                var(--cpb)  calc($clipAmount + 0.41px),
                var(--cpb) calc(100% - $clipAmount - 0.41px),
                calc($clipAmount + 0.41px) calc(100% - var(--cpb)),
                calc(100% - $clipAmount - 0.41px) calc(100% - var(--cpb)),
                calc(100% - var(--cpb)) calc(100% - $clipAmount - 0.41px),
                calc(100% - var(--cpb)) calc($clipAmount + 0.41px),
                calc(100% - $clipAmount - 0.41px) var(--cpb),
                calc($clipAmount + 0.41px) var(--cpb),
                var(--cpb) calc($clipAmount + 0.41px)
            );
        }
        .locale-item {
            color: #fff;
            width: 100%;
            font-size: 1rem;
            font-weight:bold;
            margin:0;
            padding:8px 64px 8px 16px;
            border: 1px solid transparent;
            border-color: transparent transparent rgba(black,.4) transparent;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                text-decoration-color: #6e49bc;
            }
            &.active{
                color: #858585;
                text-decoration: underline;
                text-decoration-color: #858585;
                cursor:default;
                &:hover {                    
                    text-decoration-color:  #858585;
                }
            }
        }
        transition: max-height 0.5s ease;
        li{transition: all 0.5s ease;}
    }       
}


.slide-enter-active,
.slide-leave-active {
    max-height: 0px;
    li{opacity: 0;}
}

.slide-enter,
.slide-leave-to {
    max-height: 0px;
    opacity: 0;
   // transform: scaleY(0); 
    li{opacity: 0;}
}

.slide-enter-to,
.slide-leave {
    max-height: 92px;
   // transform: scaleY(1); /* Start larger */
    li{opacity: 1;}
}

.slide-in {
    
    &-leave-active {
        transition: opacity .2s linear, transform .3s ease-in-out;
        transition-delay: calc(  (var(--total) - var(--i))s ), 0s;
        transform: translateY(calc( var(--i) * -36px));
        opacity: 1;
    }
    
    &-enter-active { 
        transition: opacity .2s linear, transform .3s ease-in-out;
        transition-delay: calc( 0.1s * var(--i) ), 0s;
    }

    &-enter, 
    &-leave-to {
        opacity: 0;
    }
    &-leave-to {
        transform: translateY(calc( var(--i) * -36px));
    }    

}
.slide-in-enter-to{ animation: springPop .5s ease-out; animation-delay: calc( 0.1s * var(--i) );}


</style>

<script>
import { nextTick } from 'vue'
const storage = window.localStorage;
const locales = [
      {name:'English',  supported:['en'], iso:'en', translation:'English' },
      {name:'Chinese',  supported:['cn'], iso:'cn', translation:'简体中文' },//(Simplified)
  ];
export default {
    props:{
        right: { type: Boolean, default: false },
        left: { type: Boolean, default: false },
    },
    data(){
        return {
             // state of the hover - toggled by clicking parent or only set to false by mouse leave once the mouse enters a selectable item 
            isOpen:false,
            iconName: ['fas', 'language'],
        }
    },

    created(){
        let key = 'en';
        let qs = new URLSearchParams(window.location.search);
        
        if(qs.has('locale')){
            key = qs.get('locale');
        }else {
            key = (storage.getItem('locale') || (navigator.language || navigator.userLanguage));                
        }
        this.switchLocale(key);     
    },
    
    computed:{
        // gets the current set locale's translated text for display to the user
        localDisplay(){
            return locales.find(locale => locale.iso== this.$i18n.locale).translation || locales[0].translation;
        },
        getLocales(){
            return !!this.isOpen ? locales[0].iso== this.$i18n.locale ? locales.reverse() : locales : [];
        }
    },
    methods:{
        switchLocale(lang){
            this.$i18n.locale = this.GetSupportedLocale(lang);
            storage.setItem('locale',this.$i18n.locale); 
            //this.$emit('click');
        },
        GetSupportedLocale(key){
            try{
                let k =  (key.split('-')[0]).toLowerCase();
            for(let l=0;l < locales.length;l++){
                if(locales[l].supported.indexOf(k)!=-1){
                    return locales[l].iso;
                }
            }
            return 'en';
        }catch(e){
            alert(key);
        }    
        },
        Open(e){
            e.stopPropagation();
            if(this.isOpen){
                return this.Close();
            }
            this.isOpen=true;
            nextTick(() => { document.addEventListener("click", this.CloseEvent, false) } );
        },        
        Close(){
            this.isOpen=false;
            document.removeEventListener("click", this.CloseEvent, false);
        },
        CloseEvent(e){
            // only close on clicks out side
            if (!this.$el.contains(e.target)) {
                this.Close();
            }
        }
    }
}
</script>