import { createApp }    from 'vue'
import App              from './App.vue'
import router           from './router'
import store            from './store'
import i18n             from './i18n'

import { library }          from '@fortawesome/fontawesome-svg-core'
import { faGlobe, faLanguage,faUserSecret  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faGlobe,faLanguage,faUserSecret );

createApp(App)
.component('font-awesome-icon',FontAwesomeIcon)
.use(store)
.use(router)
.use(i18n)
.mount('#app') 
