<template>
    <h4 v-if="Title" class="text-center" style="color: var(--bs-light);padding: 0px;">{{ Title }}</h4>
     <div class="d-flex justify-content-evenly align-items-center fs-2 text-white qty-range">
        <span @click="MinusOne">-</span>
        <input type="range" class="form-range primary w-75" min="1" :max="MaxQty" v-on:input="RangeChange($event)"  v-model="quantity" >
        <span @click="AddOne">+</span>
    </div>
    <div class="d-flex justify-content-around align-items-center text-white">
        <div class="quantity_box" >
            <img style="width: 64px;" :src="Icon"> X{{ QuantitySelected }}
        </div>
        <div class="d-flex flex-column" style="flex-wrap: nowrap; align-items: flex-start; justify-items: start; padding: 0 24px; white-space: nowrap;">
        
            <span style="font-size:8pt">$({{USDTSymbol}})</span>
            
            <div v-if="ready">
                <img :src="USDTIcon" width="24" height="24" /> {{ QuantitySelected* (price) }}                
            </div>
            <div v-else class="dot-flashing" style="right:-22px;"></div>
        </div>
    </div>

    <button v-if="connected" :class="['btn btn-primary text-center border-white btn_2', {'btn_2_green':isApprove}]" type="button" :disabled="isDisabled" @click="handleClick">
        {{ isApprove?$t('button_buy'):$t('button_approve') }}
    </button>
    <button v-else class="btn btn-primary text-center border-white btn_2" type="button" @click="ConnectWallet()" >{{ $t('wallet_connect') }}</button>
</template>

<style lang="scss">
    .btn_2_green{
        background-color: #00b186 !important;
    }
    .qty-range{
        span{
            cursor: pointer;
            font-family:    "ode", sans-serif;
            &:hover{
                color:#03FFC0;
            }
        }
        input[type=range]::-webkit-slider-thumb {
        // box-shadow: 1px 1px 1px #000000;
            border: 1px solid #fff;
        //   height: 30px;
        //  width: 15px;
        //  border-radius: 5px;
            background: #6e49bc;
        //  cursor: pointer;
        //  -webkit-appearance: none;
        //   margin-top: -11px;
        }
    }
</style>

<script>
import { isDisabled } from 'bootstrap/js/src/util';
import { mapGetters } from 'vuex';
import EventBus from '../../EventBus.js'


    export default{
        props:["Title", "Icon","max", "price", "ready", "supply","isApprove"],
        emits: ['approve', 'purchase'],
        data(){
            return {
                quantity:1,
                minQty:1
            }
        },
        computed:{ 
            ...mapGetters('ethers',[ 'connected' ]),   
            HexSymbol()     {return process.env.VUE_APP_HEX_SYMBOL},   
            WtcSymbol()     {return process.env.VUE_APP_WTC_SYMBOL},        
            USDTSymbol()    {return process.env.VUE_APP_USDT_SYMBOL},
            HexIcon()       {return process.env.VUE_APP_HEX_ICON},
            WtcIcon()       {return process.env.VUE_APP_WTC_ICON},
            USDTIcon()      {return process.env.VUE_APP_USDT_ICON},
            isDisabled()    {return !this.ready || this.supply<1},
            QuantitySelected() {return this.Clamp(this.quantity, this.minQty, this.MaxQty)},
            MaxQty() {
                let f = Math.min((this.max??5), this.supply??0)
                console.log("MaxQty", f);
                return f;
            } 
        },
        methods:{
            AddOne(){ this.quantity = this.Clamp(this.quantity+1, this.minQty, this.MaxQty) },
            MinusOne(){this.quantity = this.Clamp(this.quantity-1, this.minQty, this.MaxQty)},
            RangeChange(event){ this.quantity = this.Clamp(event.target.value, this.minQty, this.MaxQty);},
            Clamp(value, min, max){ let newval = Math.min(max, Math.max(value,min));  return newval;},
            ConnectWallet () { EventBus.emit(process.env.VUE_APP_WALLET_CONNECT_EVENT); },
            handleClick () {
                if (this.isApprove) {
                    this.$emit('purchase', this.QuantitySelected)
                } else { 
                    this.$emit('approve', this.QuantitySelected)
                }
            }
        } 

    }

</script>