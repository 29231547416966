
import {Ethers, MSG} from '../../scripts/ethers.js'
import {CONTRACT_GLO, CONTRACT_HEX, CONTRACT_WTC, CONTRACT_USDT} from '../../abi';
export default {

    InitContracts({commit, dispatch}){
        commit('gloContract', Ethers.GetContract(CONTRACT_GLO.address, CONTRACT_GLO.abi));
        commit('hexContract', Ethers.GetContract(CONTRACT_HEX.address, CONTRACT_HEX.abi));
        commit('wtcContract', Ethers.GetContract(CONTRACT_WTC.address, CONTRACT_WTC.abi));
        commit('usdtContract', Ethers.GetContract(CONTRACT_USDT.address, CONTRACT_USDT.abi));
        dispatch('SyncAll');
    },

    SyncAll(ctx){
        ctx.dispatch('PullAbey');
        ctx.dispatch('PullGlo');
        ctx.dispatch('PullHex');
        ctx.dispatch('PullUsdt');
        ctx.dispatch('PullWtc');
        ctx.dispatch('PullBaseURI');
    },
    async PullBaseURI({commit, state}){
        commit("nftBaseURI", await state.wtcContract.baseURI());
    },

    async PullAbey({commit}){
        try{
            commit('abeyFetching', true);
            commit('abeyBalance', Ethers.ShortEth(await Ethers.GetBalance()));
            commit('abeyFetching', false);
        }catch(e){
            commit('abeyFetching', false);
            console.log("PullAbey", e);
        }
        
    },
    async PullGlo({commit, state}){
        try{
            commit('gloFetching', true);
            commit('gloBalance', await state.gloContract.balanceOf(Ethers.Address()));
            commit('gloFetching', false);
        }catch(e){
            commit('gloFetching', false);
            console.log("PullGlo", e);
        }
        
    },
    async PullHex({commit, state}){
        try{
            commit('hexFetching', true);
            commit('hexBalance', await state.hexContract.balanceOf(Ethers.Address()));
            commit('hexFetching', false);
        }catch(e){
            commit('hexFetching', false);
            console.log("PullHex", e);
        }
    },
    async PullUsdt({commit, state}){
        try{
            commit('usdtFetching', true);
            commit('usdtBalance', Ethers.FromWei(await state.usdtContract.balanceOf(Ethers.Address())));
            commit('usdtFetching', false);
        }catch(e){
            commit('usdtFetching', false);
            console.log("PullUsdt", state.usdtContract, e);
        }
    },    
    async PullWtc({dispatch, commit, state}){
        try{
            commit('wtcFetching', true);
            commit('wtcBalance', await state.wtcContract.balanceOf(Ethers.Address()));
            commit('wtcFetching', false);
            dispatch('PullWtcNfts');
        }catch(e){
            commit('wtcFetching', false);
            console.log("wtcFetching", e);
        }
    },
    async PullWtcNfts({commit, state}){
        let owned =[];
        for(let i=0; i<state.wtcBalance; i++){
            console.log("Pulling Card");
            let id = await state.wtcContract.tokenOfOwnerByIndex(Ethers.Address(), i);
            let hash = await state.wtcContract.tokenIDToTokenHash(parseInt(id));
            owned.push({id:parseInt(id),hash:hash});                
            //https://stuzy2lql2.execute-api.us-east-1.amazonaws.com/testing/card/v2/0565a13c-2ec9-4f81-bf75-24744cdc6337/sprite
        }
        commit('ownedNFTs', owned);
    }

    
}
