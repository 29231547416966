import { ethers } from "ethers";
import ethnets from "./ethnets.js";
const { VUE_APP_CHAIN_RPC } = process.env;

class ethersjs{
    #provider           // the chain connection for network set in wallet
    #signer             // the account's singer for the wallet
    #chainId            // the chain ID for the current network set by the provider/wallet i.e USER
    #walletAddress      // the address for the wallet
    #events             // event/message system
    #directProvider     // direct public access to the correct chain - this is for no wallet/account so we can pull the public info, only needed if no wallet/account

    static MSG = {
        NO_SUPPORT:      "No ethereum api found",
        INIT_DONE:       "ethereum ready",
        WALLET_LOCKED:   "Permissions request pending",
        AUTH_CANCELED:   "Connect was canceled",
        ACCOUNT_CHANGED: 'Ethereum account changed',
        CHAINID_CHANGED: 'Ethereum network changed',
        DISCONECTED:     'Wallet was disconnected',

        NO_WALLET: 'No Ethereum wallet detected',
        
        NOT_CONNECTED: 'Not connected to Ethereum network',
        NOT_READY: 'Ethereum network not ready',        
        ETHERS_VUEX_INITIALIZED: 'Ethers vuex module initialized',
        ETHERS_VUEX_READY: 'Ethers vuex module ready'
    }

    

    constructor(){
        this.#provider          = null;
        this.#signer            = null;
        this.#chainId           = null;
        this.#walletAddress     = null;
        this.#events            = new EventTarget();
        this.#directProvider    = new ethers.getDefaultProvider(VUE_APP_CHAIN_RPC);
    }

    // sets up everything to start interacting with block chain
    // does not connect - the user must initate that action, this just sets all up to make it possible
    Init(){
        // see if ethereum was injected into the browser
        if(!this.EthereumSupported()){
            this.#emit(ethersjs.MSG.NO_SUPPORT, "init could not be completed, ethereum was not found on the client.");
            return false;
        }
        // grab the current id so we know what network is set
        this.#chainId = ethereum.chainId;
        // get the ethereum provider
        this.#SetProvider();

        // reg to ethereum events
        ethereum.on('accountsChanged',  (accounts) => this.#handleAccountChanged(accounts)); // fires on load and if the user changes which account is set
        ethereum.on('chainChanged',     (chainID)  => this.#handleChainChanged(chainID));   // fires if the user switches the network
        ethereum.on('message',          (message)  => console.log("message event", message.type, message.data));
        ethereum.on('connect',        (connectInfo) => console.log("connect event", connectInfo.chainId));
        ethereum.on('disconnect',     (providerRpcError) => console.log("disconnect event", providerRpcError));
        
        this.#emit(ethersjs.MSG.INIT_DONE, "init completed! ethereum was found on the client and is ready.");
        return true;
    }
    
    // checks to see if there is an account already connected
    // and sets it if found
    async CheckForAccount(){
        let accounts = await this.GetAccounts()           
        if(accounts.length>0) { this.#handleAccountChanged(accounts); }
        return accounts;       
    }

    // Our event System
    On      = (event, callback) => this.#events.addEventListener(event, callback); // Outside event register for any events we might 'emit'    
    #emit   = (event, mssage) =>   this.#events.dispatchEvent(new CustomEvent(event, { detail: mssage })); // Emit Events

    // if has wallet plugin or ethereum is supported in the browser
    // this is Metamask I beleive and there is others to checkfor
    EthereumSupported = () => !!window.ethereum;

    // for chainId conversion and what ever hex use there is with Ether
    ToHex   = (num) => `0x${num.toString(16)}`;
    FromHex = (hex) => parseInt(hex.replace(/^(0x)/,''), 16);
    
    ToWei   = (num) => ethers.parseEther(num.toString());
    FromWei = (num) => ethers.formatEther(num);

    GetNetwork = () => ethnets.list.find(n=>n.chainHex==ethereum.chainId);
    
    GetAccounts  = async () => await this.#provider.listAccounts();                         // gets array of connected wallet address -> only one is supported so the connected account is always accounts[0]
    GasPrice     = async () => await this.#provider.getGasPrice();                          // Current Suggested gas priece
    BlockNumber  = async () => await this.#provider.getBlockNumber();                       // Returns the block number (or height) of the most recently mined block
    GetBalance   = async () => ethers.formatEther( await this.#provider.getBalance(this.#walletAddress));        // Ether balance for account
    GetENS       = async (address) => await this.#provider.lookupAddress(address);          // Ethereum Naming Service (ENS) for any address if one was created    
    GetWalletENS = async () => await this.GetENS(this.#walletAddress);                      // get the ens for the wallet
    Address      = () =>this.#walletAddress;


    Connect(){
        return new Promise ((resolve, reject) => {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts)=>{
                console.log("accounts", accounts)  
                resolve(accounts[0]);
            })
            .catch ((error) => {
                switch(error.code){
                    case 4001:
                        // User rejected request so we dont do anything 
                        this.#emit(ethersjs.MSG.AUTH_CANCELED, "User canceled wallet permissions request")
                    break; 
                    case -32002: 
                        //Request of type 'wallet_requestPermissions' already pending
                        // if there is a pending request we can not interact with the wallet or open the popup form code
                        // pop an ugly alert telling the user they need to rectify the pending requests in thier wallet to unlock it
                        //alert("Your Wallet has a pending request you need to resovle directly. \n Open your wallet manually to resovle all request.");
                        // better User experiance to pop a nice modal by listening for this event
                        this.#emit(ethersjs.MSG.WALLET_LOCKED, "Request of type 'wallet_requestPermissions' already pending");
                    break; 
                }
                reject(error);
            });
            
        });

    }

    // Request Wallet to Add custom Token
    async RequestToAddChain(tokenAddress,tokenSymbol,tokenImage,tokenDecimals ){
        try{
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            let wasAdded = await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: {
                    chainId: '0xb3', // A 0x-prefixed hexadecimal string
                    chainName: 'ABEY',
                    nativeCurrency: {
                        name: 'ABEY',
                        symbol: 'ABEY', // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls:["https://rpc.abeychain.com"],
                    blockExplorerUrls:["https://scan.abeychain.com"],
                    iconUrls:[""] // Currently ignored.
                },
            }); 
            return wasAdded;               
        }catch (error) {
            return error;
            console.log(error);
        }
    }

    // Request Wallet to Add custom Token
    async RequestAddTokenToWallet(tokenAddress,tokenSymbol,tokenImage,tokenDecimals ){
        try{
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            let wasAdded = await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress,   // The address that the token is at.
                        symbol: tokenSymbol,     // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: tokenImage,       // A string url of the token logo
                    },
                },
            });
            return wasAdded;
        }catch (error) {
            return error;
            console.log(error);
        }
       
    }
    async RequestImportNftToWallet(tokenAddress, tokenId){
        try{
            let wasAdded = await window.ethereum.request({
                "method": "wallet_watchAsset",
                "params": {
                  "type": "ERC721",
                  "options": {
                    "address": tokenAddress,
                    "tokenId": tokenId
                  }
                }
            });
            return wasAdded;
        }catch (error) {
            return error;
            console.log(error);
        }
    }

    // Signing
    SignMessage = async () => this.#signer.signMessage("This is a Test Signing Message")
        .then(s=>console.log(`SignMessage: ${s}`))
        .catch(e=>console.log('error',`SignMessage: ${e}`))
    
    SignTransaction = async () => this.#signer.SignTransaction("Hello World")
        .then(s=>console.log(`SignTransaction: ${s}`))
        .catch(e=>console.log('error',`SignTransaction: ${e}`))
    

    // contracts
    GetContract         = (contractAddress, abi) => new ethers.Contract(contractAddress, abi, this.#provider);  // using the wallet account/chain READ-ONLY   
    GetSignedContract   = (contractAddress, abi) => new ethers.Contract(contractAddress, abi, this.#signer);    // using the wallet account/chain as SIGNER - WRITES
    GetDirectContract   = (contractAddress, abi) => new ethers.Contract(contractAddress, abi, this.#directProvider); // no wallet, direct interaction with public functions

    //formats
    FormatEther    = (amount)   => ethers.formatEther(amount);
    FormatUnits    = (amount,d) => ethers.formatUnits(amount,d);
    ParseEther     = (data)     => ethers.parseEther(data);  
    ShortEth       = (eth)      => {
        console.log("ShortEth", eth);
        if (eth.toString().includes('.')) {
            const parts = eth.split('.');
            return parts[0] + '.' + parts[1].slice(0, 3);
        }
        return eth;
    }
    

    #SetProvider(){
        try{
            //this.#provider = new ethers.providers.Web3Provider(window.ethereum);
            this.#provider = new ethers.BrowserProvider(window.ethereum);
            // TODO: reg to provider events events -> https://docs.ethers.io/v5/api/providers/provider/#Provider--event-methods
            // this.#provider.on('block', (blockNumber) =>  console.log('Block mined:', blockNumber) ) // Emitted on every block change
            // this.#provider.on('pending', (tx) =>  console.log('Pending:', tx) ) // Emitted when any new pending transaction is noticed

            // *** This is for listening on a transaction being proccessed
            // this.#provider.once(txHash, (transaction) => callBack)// Emitted when the transaction has been mined
        }catch(e){
            alert(e);
        }
    }

    #handleChainChanged(chainId){
        console.log("chain changed event", chainId, this.GetNetwork());
        this.#chainId=chainId;
        this.#emit(ethersjs.MSG.CHAINID_CHANGED, chainId);
    }
    async #handleAccountChanged(accounts){
        // User disconnected wallet
        if (accounts.length === 0) {            
            console.log('Please connect to MetaMask.');
            this.#walletAddress = null;
            this.#emit(ethersjs.MSG.DISCONECTED, "User disconected wallet");
        // wallet was connected    
        } else if (accounts[0] !== this.#walletAddress) {            
            this.#walletAddress =  accounts[0];        
            this.#SetProvider();
            this.#signer = await this.#provider.getSigner();
            this.#emit(ethersjs.MSG.ACCOUNT_CHANGED, "User switched accounts");
            this.#signer.getAddress().then((a)=>{
                console.log(`${accounts[0]} - ${a}`);
               // this.#emit(ethersjs.MSG.ACCOUNT_CHANGED, "User switched accounts");
            });
            //const event = new CustomEvent('accountChanged', { detail: this.currentAccount });
            //this.events.dispatchEvent(event);
        // Do any other work!
        }
    }
    


     

}

export const Ethers = new ethersjs();
export const MSG = ethersjs.MSG;

