<template>
<div>
     <header class="page-header">
        <div class="row mb-5">
            <div class="col-md-8 col-xl-6 text-center mx-auto">
                <h1><strong>{{$t('nav_tokens')}}</strong></h1>
                <hr class="hr-purple-center">
            </div>
        </div>
    </header>
    <section class="play-and-earn clean-block">
        <div class="container py-4 py-xl-5" style="/*margin-bottom: 0;*/">
            <div class="row" style="width: 100%;margin: auto;/*margin-bottom: 150px;*//*margin-top: 150px;*/">
                <div class="col-md-8 col-xl-8 col-xxl-7 text-center mx-auto">
                    <!--h1 class="text-center" style="padding: 50px 0 200px 0;font-size: 85px;"><span style="color: rgb(250, 248, 255);">{{$t('coming_soon')}}!</span></h1-->
                    <div class="d-flex align-items-center align-items-md-start align-items-xl-center" style="padding: 40px 0;">
                        <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 stone"><img src="/assets/img/glostone.png" style="width: 100%;"></div>
                        <div class="text-start">
                            <h3 style="color: rgb(255,255,255);">Glöstöne</h3>
                            <p style="color: var(--bs-info-bg-subtle);">$({{GloSymbol}})</p>
                            <p style="color: rgb(255,255,255);">{{ $t('homeview_glo_text') }}</p>
                            <!--a class="btn btn-primary active btn_arrows" role="button" href="/glöstöne.html"><img src="/assets/img/arrow_left.png">GET TOKEN<img src="/assets/img/arrow_right.png"></a -->
                            <ImportGloBtn  />
                        </div>
                    </div>
                    <div class="d-flex align-items-center align-items-md-start align-items-xl-center" style="padding: 40px 0;">
                        <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center stone"><img src="/assets/img/hex.png" style="width: 100%;"></div>
                        <div class="text-start">
                            <h3 style="color: rgb(255,255,255);">Hex Ore</h3>
                            <p style="color: var(--bs-info-bg-subtle);">$({{ HexSymbol }})</p>
                            <p style="color: rgb(255,255,255);">{{ $t('homeview_hex_text') }}</p>
                            <!-- a class="btn btn-primary active btn_arrows" role="button" href="/glöstöne.html"><img src="/assets/img/arrow_left.png">GET TOKEN<img src="/assets/img/arrow_right.png"></a -->
                            <ImportHexBtn />
                        </div>
                    </div>
                    <div class="text-start d-flex align-items-center align-items-md-start align-items-xl-center" style="padding: 40px 0;">
                        <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center stone"><img src="/assets/img/nft_card.png" style="width: 100%;"></div>
                        <div>
                            <h3 style="color: rgb(255,255,255);">{{ $t('homeview_nft') }}</h3>
                            <p style="color: rgb(255,255,255);">{{ $t('homeview_nft_text') }}</p>
                            <router-link class="btn btn-primary active btn_arrows" to="/market" role="button" ><img src="/assets/img/arrow_left.png">{{ $t('get_nfts') }}<img src="/assets/img/arrow_right.png"></router-link>
            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BuildSection />
    <!--section class="build" style="background: var(--bs-info);">
        <div class="container py-4 py-xl-5">
            <div class="row mb-5">
                <div class="col-md-8 col-xl-6 text-center mx-auto">
                    <h2 class="display-5 fw-bold">{{ $t('homeview_build') }}</h2>
                    <hr class="hr-purple-center">
                    <h4 v-html="$t('homeview_text')"></h4>    
                </div>
            </div>
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/market_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('market')}}</h3>
                        <router-link class="btn btn-primary active btn_1" role="button" to="/market">{{$t('market_place')}}</router-link>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/dev_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('dev')}}</h3><a class="btn btn-primary btn_1" role="button" href="#">{{$t('coming_soon')}}</a>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/doc_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{ $t('docs') }}</h3><a class="btn btn-primary btn_1" role="button" href="#">{{$t('coming_soon')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section-->
</div>
</template>


<script>
// @ is an alias to /src
import ImportGloBtn from '../components/tokenImport/gloImportButton.vue'
import ImportHexBtn from '../components/tokenImport/hexImportButton.vue'
import BuildSection from '../components/buildSection.vue'
export default {
    components:{ImportHexBtn,ImportGloBtn,BuildSection},
    computed:{        
        GloSymbol() {return process.env.VUE_APP_GLO_SYMBOL},    
        HexSymbol() {return process.env.VUE_APP_HEX_SYMBOL} ,
    }

}
</script>
