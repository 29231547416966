<template>
    <div v-if="showCard && meta==null">
        loading
        <br>
              
    </div>
    <div v-else-if="showCard" class="row">
        <div class="col-6 card-visual-container" >
            <model-viewer :src="model" camera-controls=""  camera-orbit="180deg 90deg" ></model-viewer>
        </div>
        <div class="col-6">
            <ul class="list-group">
                <li class="list-group-item">Name: {{ cardName }}</li>
                <li class="list-group-item">description: {{ description }}</li>
                <li class="list-group-item" v-for="(item, i) in attributes" :key="i"> {{ item.trait_type }} : {{ item.value }}</li>
            </ul>
        </div>
    </div>
    <div v-else class="row justify-content-center">

        <div class="col-sm-12 col-md-6 ">
            <h3>Cards Owned</h3>    
            <router-link v-for="(v,i) in ownedNFTs" :key="i" class="hash text-center" :to='{name:"card_view", params:{hash:v.hash}}'>
                Card[ID({{v.id}}), Hash:{{v.hash}}]
            </router-link>
        </div>
        
    </div>
</template>
<style lang="scss">
.hash{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
    .card-visual-container{
        display: flex;
        align-items: center;
        justify-content: center;
        model-viewer{
            height: 342px;
        }
    }
</style>
<script>

const storage = window.localStorage;
import {modelViewer} from '@google/model-viewer';
import { mapGetters } from 'vuex';

export default {
    components:{modelViewer},
    data(){
        return {
            hash:null,
            meta:null,
            wtcContract:null
        }
    },  
    created(){
        try{
            this.hash = this.$route.params.hash??"";
            console.log("HASH", this.hash);
            if(this.showCard){
                this.pullMeta();
            }
        }catch(e){
            console.log("HASH",e);
        }
            
    },
    computed:{
        ...mapGetters(
            'wallet',[ 
            'nftBaseURI',  
            'wtcBalance',    
            'wtcFetching',  
            'ownedNFTs',
            'wtcContract'     
        ]),
        showCard(){     return this.hash.length>0;},
        metadata() {    return `${this.nftBaseURI}${this.hash}/metadata`;},
        cardName(){     return this.meta['name']??"";},
        description(){  return this.meta['description']??"";},
        sprite() {      return this.meta['image']??"";},
        model(){        return this.meta['animation_url']??""},
        attributes() {  return this.meta['attributes']??[];},

       /* attributes
        {trait_type: 'OP', value: true}
        {trait_type: 'Type', value: 'Unit'}
        {trait_type: 'Rarity', value: 'Boring'}
        {trait_type: 'Faction', value: 'Glorpan'}
        {trait_type: 'Point Value', value: 1} */
    },
    methods:{
        async pullMeta(){
            console.log("FETCHING", this.metadata)
            let res = await fetch(this.metadata);
            if(res.ok){
                this.meta = await res.json();
            }
            console.log("FETCHING Response", res)
            console.log(this.meta);
        }
    }
}
</script>