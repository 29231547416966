/* eslint-disable */
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import {Ethers} from '../../scripts/ethers.js'
import {CONTRACT_GLO, CONTRACT_HEX, CONTRACT_WTC, CONTRACT_USDT} from '../../abi';


const state = () => ({
    nftBaseURI:"",
    gloBalance:0,
    hexBalance:0,
    abeyBalance:0,
    usdtBalance:0,
    wtcBalance:0, 
    gloFetching:false,
    hexFetching:false,
    abeyFetching:false,
    usdtFetching:false,
    wtcFetching:false,
    ownedNFTs:[],
  // these, default, will not be hooked to the user wallet, on connect it will be reset with wallet connection 
    gloContract      : Ethers.GetContract(CONTRACT_GLO.address, CONTRACT_GLO.abi),
    hexContract      : Ethers.GetContract(CONTRACT_HEX.address, CONTRACT_HEX.abi),
    wtcContract      : Ethers.GetContract(CONTRACT_WTC.address, CONTRACT_WTC.abi),
    usdtContract     : Ethers.GetContract(CONTRACT_USDT.address, CONTRACT_USDT.abi),
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
