/* eslint-disable */
export default {
    nftBaseURI:     function (state, value) { state.nftBaseURI = value },
    gloBalance:     function (state, value) { state.gloBalance = value },
    hexBalance:     function (state, value) { state.hexBalance = value },
    abeyBalance:    function (state, value) { state.abeyBalance = value },
    usdtBalance:    function (state, value) { state.usdtBalance = value },
    wtcBalance:     function (state, value) { state.wtcBalance = value },

    gloFetching:    function (state, value){ state.gloFetching=value },
    hexFetching:    function (state, value){ state.hexFetching=value },
    abeyFetching:   function (state, value){ state.abeyFetching=value },
    usdtFetching:   function (state, value){ state.usdtFetching=value },
    wtcFetching:    function (state, value){ state.wtcFetching=value },

    ownedNFTs: function (state, value) { state.ownedNFTs = value },
    
    gloContract: function (state, value) { state.gloContract = value },
    hexContract: function (state, value) { state.hexContract = value },
    wtcContract: function (state, value) { state.wtcContract = value },
    usdtContract: function (state, value) { state.usdtContract = value }
    
    
  
  }
  