<template>
  <navbar />
  <router-view v-slot="{Component, route}">
    <transition :name="route.meta.transition || 'fade'" >
      <component :is="Component" :key="route.path" />
    </transition>
  </router-view>

  <wwFooter />
</template>

<script>

import navbar from "./components/navbar.vue";
import wwFooter from "./components/footer.vue";
export default {
  
  components: {
    navbar,
    wwFooter
  },
  created(){
      this.$store.dispatch('ethers/init');
  }
}
</script>
