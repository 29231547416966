/* eslint-disable */
export default {
    supported   :(state) => state.supported,
    initialized :(state) => state.initialized,
    connected   :(state) => state.connected,
    error       :(state) => state.error,
    address     :(state) => state.address,
    ens         :(state) => state.ENS,
    network     :(state) => state.network
}

