/* eslint-disable */
import getters from './getters'
import actions from './actions'
import mutations from './mutations'


const state = () => ({
  // libarary stuff
  supported:false,
  initialized: false,
  error: null,
  // account stuff
  connected: false,
  address: '',
  network: '',
  ens: null
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
