import { createStore } from 'vuex'
import ethers from './ethers'
import wallet from './wallet'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ethers,
    wallet
  }
})
