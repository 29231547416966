import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
//import NewsView from '../views/NewsView.vue'
import TokenView from '../views/TokensView.vue'
import MarketView from '../views/MarketView.vue'
import NFTView from '../views/NFTView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
    //meta: { transition: 'slide-left' },
  },
 /* {
    path:"/news",
    name:'news',
    component:NewsView
  },*/
  {
    path:"/tokens",
    name:'tokens',
    component:TokenView
  },
  {
    path:"/market",
    name:'market',
    component:MarketView
  },
  {
    path:"/nft-card/:hash?",
    name:'card_view',
    component:NFTView
  }
  //{
  //  path: '/market',
  //  name: 'market',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    // always scroll to top
    return { top: 0 }
  }
})

export default router
