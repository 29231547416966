export default {
    list:[
        {   
            chainID:    1,
            chainHex:   '0x1',
            name:   'Mainnet',
            isTest: false
        },
        {   
            chainID:    2,
            chainHex:   '0x2',
            name:   'Morden (deprecated)',
            isTest: false
        },
        {   
            chainID:    3,
            chainHex:   '0x3',
            name:   'Ropsten',
            isTest: true
        },

        {   
            chainID:    4,
            chainHex:   '0x4',
            name:   'Rinkeby',
            isTest: true
        },
        {   
            chainID:    5,
            chainHex:   '0x5',
            name:   'Goerli',
            isTest: true
        },
        {   
            chainID:    42,
            chainHex:   '0x2a',
            name:   'Kovan',
            isTest: true
        },
        {   
            chainID:    179,
            chainHex:   '0xb3',
            name:   'ABEY',
            isTest: false
        },
        {   
            chainID:    1337,
            chainHex:   '0x539',
            name:   'localhost',
            isTest: true
        }
    ]
    
}