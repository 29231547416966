<template>
    <div>
        <slot name="trigger" :onClick="show"></slot>

        <div class="modal fade" :class="styles" tabindex="-1" ref="modal">               
            <div class="modal-dialog" :class="dialogStyles">
                <div class="modal-content border-0" :class="contentStyles">
                    <slot v-if="hasHeader" name="header"></slot>
                    <slot v-if="hasBody" name="body"></slot>
                    <slot v-if="hasFooter" name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    props:['onOpen','onClose', 'styles', 'dialogStyles', 'contentStyles'],
    data(){
        return{
            theModal:null
        }
    },
    computed:{
        hasTrigger(){return !!this.$slots.trigger;},
        hasHeader(){return !!this.$slots.header;},
        hasBody(){return !!this.$slots.body;},
        hasFooter(){return !!this.$slots.footer;},
    },
    methods:{
        show(){            
            console.log("show");
            if(this.theModal) this.theModal.show()
        }
    },
    mounted() {
        this.theModal = new Modal(this.$refs['modal'], {});
        console.log("MODAL",this.$refs['modal']);
        console.log("$el",this.$el);
        document.body.append(this.$refs['modal']);
       
        if(this.onOpen){
            this.$refs['modal'].addEventListener('show.bs.modal', ()=>{
                this.onOpen();               
            });
        }
        if(this.onClose){
            this.$refs['modal'].addEventListener('hidden.bs.modal', ()=>{
                this.onClose();               
            });
        }
        this.$emit("mounted", this.theModal);
    }
}
</script>