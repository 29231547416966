/* eslint-disable */
/*
import {
  MSGS,
  EVENT_CHANNEL,
  connect,
  event,
  ready,
  getProvider,
  getWallet,
  getWalletAddress,
  getNetName,
  hasEns
} from './ethersConnect'
*/
import {Ethers, MSG} from '../../scripts/ethers.js'

export default {
  connect(ctx) {
    
    Ethers.Connect().then(address =>{ 
      ctx.commit('connected', true)
      ctx.commit('error', null)
      ctx.commit('address', address)
      ctx.commit('network', Ethers.GetNetwork())
     // ctx.commit('ens', await Ethers.GetWalletENS())

      //event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_READY)
      this.dispatch('wallet/InitContracts');
    }).catch(err=>{
      ctx.dispatch('disconnect', err)
    });

  },
  disconnect(ctx, err) {
    ctx.commit('connected', false)
    ctx.commit('error', err)
    ctx.commit('address', '')
    ctx.commit('network', '')
    ctx.commit('ens', null)
  },
  async logout(ctx) {
    ctx.commit('address', '')
    alert('You have been logged out from your Ethereum connection')
  },
  async notConnected(ctx) {
    ctx.commit('address', '')
    alert('You are not connected to the Ethereum network. Please check MetaMask,etc.')
  },

  async init(ctx) {
    // base set up
    let supported = Ethers.Init();
    ctx.commit('supported', supported)

    //if ethereum does not exsits its not supported for this client
    if(!supported){
      console.log("No support for Ether, install a wallet or use a browser with support");
      ctx.commit('initialized', false)
      return;
    }

    // ether Ethereum is built in or they have a wallet
    console.log("Ether Supported!!");
    ctx.commit('initialized', true);

    // see if there is an account connected already <- previous vist
    let accounts = await Ethers.CheckForAccount();           
    if(accounts.length>0) {
      ctx.dispatch('connect',accounts[0]);  
    }
 
    //event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_INITIALIZED)
    Ethers.On(MSG.DISCONECTED,     ()=>ctx.dispatch('disconnect'));
    Ethers.On(MSG.CHAINID_CHANGED, ()=>ctx.commit('network',Ethers.GetNetwork()));
    //Ethers.On(ethersjs.MSGS.NO_WALLET, ctx.dispatch('logout'));
    //Ethers.On(ethersjs.MSGS.ACCOUNT_CHANGED, ctx.dispatch('connect'));
    //Ethers.On(ethersjs.MSGS.NOT_CONNECTED, ctx.dispatch('notConnected'));
    
  }

  
}
